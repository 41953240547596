// MyComponent.js
import React, { useEffect, useState } from "react";
import CustomPopup from "../popupmodel/Popup";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import searchIcon from "../../assets/images/search.png";
import downloadIcon from "../../assets/images/download.png";
import ModalImage from "../ModalImage";

const OverView = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [popupState, setPopupState] = useState({
    isOpen: false,
    type: null,
    data: null,
  });

  const tableData = [
    {
      id: "3",
      firstName: "Sabari ",
      lastName: "M ",
      address: "No 12 1st street govindarajapuram adyar chennai - 20",
      mobileNo: "6381246687",
      alternateNo: "6381246687",
      modelName: "hp",
      product: "laptop",
      issue: "battery",
      photo: "image",
      selectSlot: "521",
      totalAmount: "1651",
      actualAmount: "65165",
      netAmount: "155",
      technicianShare: "5",
      ownerShare: "15",
      status: "Completed",
    },
    {
      requestId: "2k004",
      userName: "raja",
      serviceName: "Electrical",
      product: "Mobile",
      model: "Redmi",
      issue: "Display",
      image: "coming",
      status: "New Request",
    },

    {
      requestId: "2k006",
      userName: "raja",
      serviceName: "Electrical",
      product: "Mobile",
      model: "Redmi",
      issue: "Display",
      image: "coming",
      status: "Pending",
    },
  ];
  const getViewRequest = async () => {
    const response = await axios.get(
      // `https://api.2kvirtualworld.com/CRM/requests`
      `${process.env.REACT_APP_HOST}/requests`
    );
    return response.data; // Ensure we return the actual data from the response
  };

  const deleteItem = async (id) => {
    await axios.delete(`${process.env.REACT_APP_HOST}/requests/${id}`);
  };

  const { data, isLoading, isError, refetch } = useQuery(
    "getViewRequest",
    getViewRequest
  );

  const details = data || []; // Use the fetched data directly
  // console.log("details", details);
  const [filteredData, setFilteredData] = useState([]);

  const mutation = useMutation(deleteItem, {
    onSuccess: () => {
      console.log("Successfully deleted technician.");
      refetch();
    },
    onError: (err) => {
      console.log("Error deleting technician:", err);
    },
  });

  useEffect(() => {
    if (details?.length) {
      const applyFilterAndSearch = () => {
        const result = details?.filter((item) => {
          const matchesFilter = filter ? item?.status === filter : true;
          const matchesSearch = searchTerm
            ? (item.requestId ?? "")
                .toString()
                .toLowerCase()
                .startsWith(searchTerm.toLowerCase()) ||
              (item.serviceName ?? "")
                .toString()
                .toLowerCase()
                .startsWith(searchTerm.toLowerCase()) ||
              (item.mobileNo ?? "")
                .toString()
                .toLowerCase()
                .startsWith(searchTerm.toLowerCase()) ||
              (`${item?.firstName} ${item?.lastName}` ?? "")
                .toString()
                .toLowerCase()
                .startsWith(searchTerm.toLowerCase())
            : true;

          return matchesFilter && matchesSearch;
        });

        setFilteredData(result);
      };

      applyFilterAndSearch();
    }
  }, [filter, searchTerm, details]);

  const downloadExcel = () => {
    const exportData = filteredData?.length > 0 ? filteredData : details;

    const tableDatas = exportData?.map((data, index) => ({
      "S.NO": index + 1,
      "REQUEST ID": data?.requestID || "NULL",
      "USER NAME": `${data?.firstName}${data?.lastName}` || "NULL",
      "SERVICE NAME": data?.serviceName || "NULL",
      PRODUCT: data?.product || "NULL",
      MODEL: data?.modelName || "NULL",
      ISSUE: data?.issue || "NULL",
      STATUS: data?.status || "NULL",
    }));

    const tableWorksheet = XLSX.utils.json_to_sheet(tableDatas);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, tableWorksheet, "Table Data");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "Overview.xlsx");
  };

  const handleOpenPopup = (item) => {
    setPopupState({ isOpen: true, type: item?.status, data: item });
  };

  const handleClosePopup = () => {
    setPopupState({ isOpen: false, type: null, data: null });
  };

  // Open Modal and set the image
  const openModal = (image) => {
    setModalImage(image);

    setIsModalOpen(true);
  };

  // Close Modal
  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage("");
  };

  return (
    <>
      <div className="overview-main-block my-4">
        <div className="overview-heading-block">
          <h6 className="overview-head">OVERVIEW</h6>
          <div className="overview-search-block">
            <select
              className="overview-statusbar"
              onChange={(e) => setFilter(e.target.value)}
              value={filter}
            >
              <option value="">Status</option>
              <option value="New Request">New Request</option>
              <option value="Pending">Pending</option>
              <option value="Completed">Completed</option>
              <option value="Canceled">Canceled</option>
            </select>
            <div className="overview-download-block" onClick={downloadExcel}>
              <div className="search-icon">
                <img src={downloadIcon} alt="Download" className="crm-image" />
              </div>
              <button className="overview-btn">DOWNLOAD</button>
            </div>
            <div className="d-flex align-items-center search-block">
              <input
                type="search"
                className="table-search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="search-icon">
                <img src={searchIcon} alt="Search" className="crm-image" />
              </div>
            </div>
          </div>
        </div>
        {/* overview-table-block */}
        <div className="overview-table-main-block">
          <div className="table-responsive table-container">
            <table className="table overview-table">
              <thead className="table-heading">
                <tr>
                  <th className="overview-table-head">Request ID</th>
                  <th className="overview-table-head">User Name</th>
                  <th className="overview-table-head">Mobile Number</th>
                  <th className="overview-table-head">Service Name</th>
                  <th className="overview-table-head">Product</th>
                  <th className="overview-table-head">Model</th>
                  <th className="overview-table-head">Issue</th>
                  <th className="overview-table-head">Damage Image</th>
                  <th className="overview-table-head">Model Image</th>
                  <th className="overview-table-head">Serial Image</th>
                  <th className="overview-table-head">New Image</th>

                  <th className="overview-table-head">Status</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="8" className="text-center">
                      Loading...
                    </td>
                  </tr>
                ) : isError ? (
                  <tr>
                    <td colSpan="8" className="text-center text-danger">
                      No Network...
                    </td>
                  </tr>
                ) : filteredData.length > 0 ? (
                  filteredData.map((item) => (
                    <tr
                      key={item?.requestID}
                      onClick={() => handleOpenPopup(item)}
                      style={{ cursor: "pointer" }}
                    >
                      <td className="overview-table-data">{item?.requestID}</td>
                      <td className="overview-table-data">
                        {item?.firstName}&nbsp;{item?.lastName}
                      </td>
                      <td className="overview-table-data">{item?.mobileNo}</td>
                      <td className="overview-table-data">
                        {item?.serviceName}
                      </td>

                      <td className="overview-table-data">{item?.product}</td>
                      <td className="overview-table-data">{item?.modelName}</td>
                      <td className="overview-table-data">{item?.issue}</td>
                      <td
                        className="overview-table-data"
                        onClick={() => {
                          openModal(item?.productImage);
                        }}
                      >
                        <img
                          src={`data:image/jpeg;base64,${item?.productImage}`}
                          alt="no-image"
                          className="search-icon"
                        />
                      </td>
                      <td
                        className="overview-table-data"
                        onClick={() => {
                          openModal(item?.modelImage);
                        }}
                      >
                        <img
                          src={`data:image/jpeg;base64,${item?.modelImage}`}
                          alt="no-image"
                          className="search-icon"
                        />
                      </td>
                      <td
                        className="overview-table-data"
                        onClick={() => {
                          openModal(item?.serialImage);
                        }}
                      >
                        <img
                          src={`data:image/jpeg;base64,${item?.serialImage}`}
                          alt="no-image"
                          className="search-icon"
                        />
                      </td>
                      <td className="overview-table-data">
                        <img
                          // src={`data:image/jpeg;base64,${item?.newImage}`}
                          src="df"
                          alt="no-image"
                          className="search-icon"
                        />
                      </td>
                      <td className="overview-table-data">{item.status}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">
                      No Records Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <CustomPopup
        isOpen={popupState.isOpen}
        onClose={handleClosePopup}
        type={popupState.type}
        data={popupState.data}
        tableData={tableData}
        refetch={refetch}
      />
      {isModalOpen && <ModalImage image={modalImage} onClose={closeModal} />}
    </>
  );
};

export default OverView;
